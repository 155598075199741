<template>
  <el-main>
    <div style="margin-bottom: 10px;display: flex;">
      <el-button style="margin-right: 10px;" type="primary" class="el-icon-plus" size="small" @click="$router.push('/extension/currentprice/add')">添加时价</el-button>
      <!-- <el-upload :on-change="importExcel" ref="input" action="/" :show-file-list="false" :auto-upload="false" type="file">
        <el-button size="small">导入时价</el-button>
      </el-upload> -->
    </div>
    <!-- 搜索 -->
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="类别名：">
        <el-cascader :options="type_list" :props="{ value: 'id', label: 'name', children: 'data',checkStrictly:true,emitPath:false}" filterable clearable size="small" placeholder="请选择类别名（二级分类）"
        v-model="form.classify_ids"></el-cascader>
      </el-form-item>
      <el-form-item label="牌号名：">
        <el-input size="small" placeholder="请输入牌号名" v-model="form.brand_name"></el-input>
      </el-form-item>
      <el-form-item label="厂家名：">
        <el-input size="small" placeholder="请输入厂家名" v-model="form.supplier"></el-input>
      </el-form-item>
      <el-form-item label="价格类型：">
        <el-select v-model="form.price_type" placeholder="请选择类别名称" size="small" clearable filterable>
          <el-option v-for="item in price_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="地区（省）：">
        <el-select v-model="form.province_id" placeholder="请选择地区" size="small" clearable filterable>
          <el-option v-for="item in area_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数据来源：">
        <el-select v-model="form.comefrom" placeholder="请选择数据来源" size="small" clearable filterable>
          <el-option v-for="item in comefrom" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="search">搜索</el-button>
        <el-button size="small" @click="exportToExcel">导出</el-button>
        <el-button size="small" type="text" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="list" :header-cell-style="{'background-color': '#F8F9FA' }" id="table">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="classify_name" label="类别名" align="center"></el-table-column>
      <el-table-column prop="brand_name" label="牌号名" align="center"></el-table-column>
      <el-table-column prop="supplier" label="厂家名" align="center"></el-table-column>
      <el-table-column prop="price_type" label="价格类型" align="center">
        <template slot-scope="scope">
          {{price_type.find(item=>item.value==scope.row.price_type).label}}
        </template>
      </el-table-column>
      <el-table-column prop="province_name" label="地区（省）" align="center" width="100"></el-table-column>
      <el-table-column prop="price" label="时价价格（元/吨）" align="center" width="150"></el-table-column>
      <el-table-column prop="comefrom" label="数据来源" align="center">
        <template slot-scope="scope">{{comefrom.find(item=>item.value==scope.row.comefrom).label}}</template>
      </el-table-column>
      <el-table-column prop="name" label="上传会员昵称" align="center" width="150"></el-table-column>
      <el-table-column prop="mobile" label="会员手机号" align="center" width="150"></el-table-column>
      <el-table-column prop="add_time" width="160" label="发布时间" align="center">
        <template slot-scope="scope">
          <span>{{ getDateformat(scope.row.add_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140" align="center">
        <template slot-scope="scope">
          <!-- <el-button type="text" size="small">编辑</el-button> -->
          <el-button type="text" size="small" @click="delItem(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging.vue';
import { getDateformat } from '@/util/getDate';
import { price_type,comefrom } from './data';
import FileSaver from "file-saver";
import XLSX from "xlsx";
export default {
  components: {
    Paging,
  },
  data() {
    return {
      form: {
        page: 1,
        rows: 10,
        classify_ids: '',
        brand_name:'',
        supplier:'',
        price_type:'',
        province_id:'',
        comefrom:''
      },
      area_list: [],
      type_list: [],
      total: 0,
      list: [],
      price_type:price_type,
      comefrom:comefrom
    };
  },
  created() {
    this.getList();
    this.getArea();
    this.getType();
  },
  methods: {
    search() {
      this.form.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.form = {
        page: 1,
        rows: 10,
        classify_ids: '',
        brand_name:'',
        supplier:'',
        price_type:'',
        province_id:'',
        comefrom:''
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.form.rows = val;
        this.getList();
      } else {
        this.form.page = val;
        this.getList();
      }
    },
    getList() {
      this.$axios.post(this.$api.push.currentprice.list, this.form)
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getDateformat,
    getArea() {
      this.$axios.get(this.$api.set.area, { pid: 0 }).then(res => {
        if (res.code == 0) {
          this.area_list = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getType() {
      this.$axios.post(this.$api.push.currentprice.setInfo).then(res => {
        if (res.code == 0) {
          this.type_list = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    exportToExcel(){
      let data = this.list.concat()
      let arr = []
      for(let v of data){
        let obj = {
          '类别名':v.classify_name,
          '牌号名':v.brand_name,
          '厂家名':v.supplier,
          '价格类型':this.price_type.find(item=>item.value==v.price_type).label,
          '地区（省）':v.province_name,
          '时价价格（元/吨）':v.price,
          '数据来源':this.comefrom.find(item=>item.value==v.comefrom).label,
          '上传会员昵称':v.name,
          '会员手机号':v.mobile,
          '发布时间':this.getDateformat(v.add_time)
        }
        arr.push(obj)
      }
      const worksheet  = XLSX.utils.json_to_sheet(arr)
      const workbook = {
            SheetNames: ['sheet1'],
            Sheets: {
                'sheet1': worksheet
            }
        };
        /* 获取二进制字符串作为输出 */
        const wbout = XLSX.write(workbook, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          FileSaver.saveAs(
            new Blob([wbout], {
              type: "application/octet-stream"
            }),
            //设置导出文件名称
            "时价列表.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        return wbout;
    },
    importExcel(file) {
      const types = file.name.split(".")[1];
      const fileType = ["xlsx", "xlc", "xlm", "xls", "xlt"].some(
        item => item === types
      );
      if (!fileType) {
        alert("格式错误！请重新选择");
        return;
      }
      this.file2Xce(file).then(tabJson => {
        if (tabJson && tabJson.length > 0) {
          let datastring = JSON.stringify(tabJson[0].sheet);
          //中英文转化
          let merchantList = this.dateTransition(datastring);
          console.log(merchantList);
        }
      });
    },
    file2Xce(file) {
      return new Promise(function(resolve, reject) {
        const reader = new FileReader();
        reader.onload = function(e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: "binary"
          });
          const result = [];
          this.wb.SheetNames.forEach(sheetName => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
            });
          });
          resolve(result);
        };
        reader.readAsBinaryString(file.raw);
      });
    },
    dateTransition(data){
      let obj = {}
      let arr = []
      let outdata =JSON.parse(data);
      for(let v of outdata){
        for(let key in v){
          console.log(key,v[key])
          if(key == '类别名'){
            let type = v[key].split('/')
            let _type = []
            type.forEach(item=>{
              this.type_list.forEach(v=>{
                if(v.name==item){
                  _type.push(v.id)
                }else{
                  if(v.data){
                    v.data.forEach(t=>{
                      if(t.name==item){
                        _type.push(t.id)
                      }
                    })
                  }
                }
              })
            })
            obj['classify_ids'] = _type.toString();
          }else if(key == '牌号名'){
            obj['brand_name'] = v[key]
          }else if(key == '厂家名'){
            obj['supplier'] = v[key]
          }else if(key == '价格类型'){
            obj['supplier'] = this.price_type.find(item=>item.label==v[key]).value
          }else if(key == '地区（省）'){
            obj['province_id'] = v[key]
          }
        }
      }
    },
    delItem(id){
      this.$confirm('是否删除该项?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.post(this.$api.push.currentprice.del,{id})
          .then(res => {
            if (res.code == 0) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
        }).catch(() => {});
    }
  },
};
</script>

<style lang="less" scoped>
.el-table {
  border-left: 1px solid #e8eef1;
  border-right: 1px solid #e8eef1;

  /deep/ .el-input__inner {
    border-color: #fff;
  }

  /deep/ .el-input__inner:focus {
    border-color: #409eff;
  }
}

.el-main {
  background: #fff;
}
</style>
